#mainDetail {
	background-size: cover;
	position: absolute;
	top: 0; left:0; bottom:0; right:0;
}
#mainDetail .gradient{
	position: absolute;
	height:100%;
	width: 100%;
	background: rgb(0,0,0);
	background: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%);
}
#mainDetail .titre{
	color:#fff;
	font-size: 8vh;
	padding-top:20vh;

}
.DescFilm {
	color:#fff;
	padding-left:5vw;
}
.btnLecture{
	display:inline-block;
	font-size: 3.5vh;
	padding: 1vw 10vh;
	border: 2px solid #fba00b;
	border-radius: 10px;
}
.btn-focus{
	background-color: #fba00b;
}
.btnRetour{
	position: relative;
	top: 2vh;
	left: 2vw;
	color:#FFF;
	display:inline-block;
	font-size: 3vh;
	padding: 0.5vh 1vw;
	border: 2px solid #fba00b;
	border-radius: 10px;
}
.DescContent{
	width:50%;
	font-size:	3vh;
	line-height: 3.5vh;
	padding-top:1.5vh ;
}
.DescContent p{
	margin-top: 3vh;
	font-size:	3vh;
	line-height: 3.5vh;
}
#mainDetail .divBackground{
	z-index:0;
	position: absolute;
	top:0;
	left: 0;
	right:0;
	bottom: 0;
}
#mainDetail .imgBackground{
	position: relative;
	top:0;
	left: 0;
	width:1280px;
	height:720px;
}
img.bg{
	min-height: 100%;
	min-width: 1024px;
	  
	/* Set up proportionate scaling */
	width: 100%;
	height: auto;
	  
	/* Set up positioning */
	position: fixed;
	top: 0;
	left: 0;  
}
.rai-spinner-outer {
	border-width: 0.15em ;
}
.rai-spinner-inner{
	border-width:0.15em;
}