.custom-dot-list-style{
	bottom:5vh;
}
.react-multi-carousel-dot--active button {
	background:#FBA00B;
}
.ButtonGO{

	position: absolute;
	bottom:0;
	left:0;
	right:0;
	z-index: 1;
	border-width: 1px;
	padding: 10px;
	border-radius: 5px;
	border-color: #FBA00B;
	text-align: center;
	background-color: rgb(179, 179, 179);
	color: #FFF;
	font-family: "Balboa";
	font-size: 4vh;
}
.ButtonGO:focus{
	color:#fff;
	background-color:#FBA00B;
}