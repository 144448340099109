.myInputSearch{
	font-family: 'Balboa';
	font-size: 3.5vh;
	color: #fff;
	border-radius: 5px;
	border: 1px solid #fff;
	padding: 0 10px;
	margin-top: 2vh;
}

.myInputSearch input{
	font-family: "Balboa";
	/*margin-top: 5px; */
	background-color: black;
	font-size: 3.5vh;
	border: none;
	color:#fff;
/*
	border-radius: 5px;
	border: 1px solid white;
	color:#fff;
*/
	padding:10px;	
}

.myInputSearchFocus{
	font-family: 'Balboa';
	font-size: 3.5vh;
	color: #FBA00B;
	border-radius: 5px;
	border: 1px solid #FBA00B;
	padding: 0 10px;
	margin-top: 2vh;
}
.myInputSearchFocus input{
	font-family: "Balboa";
	/* margin-top: 5px; */
	background-color: black;
	font-size: 3.5vh;
	border: none;
	padding:10px;
	color:#fff;
	width: 90vw;
/*	
	border-radius: 5px;
	border: 1px solid #FBA00B;
	color:#FFF;
*/
}
hr{
	border-color:#636363;
	width:	80vw;
}

#ctnSearch{
	color:#fff;
}
.txtExplicatif{
	text-align: center;
	font-size: 3vh;
}
.ontainer{
	scroll-behavior: smooth;
}